<template>
<Page title="UO Konsult AB" image="home.jpg">
    <ServicesSmall></ServicesSmall>
    <Services></Services>
</Page>
</template>

<script>

const Page = () => import("@/components/page/Page");
const ServicesSmall = () => import("@/components/home/ServicesSmall");
const Services = () => import("@/components/home/Services");


export default {
    name: 'Home',
    components: {
        Page,
        ServicesSmall,
        Services        
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
        MediaImage(id) {
            return this.$images.getMediaImage(id);
        }
    }
};
</script>
