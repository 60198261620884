<template>
<Page title="Kontakta oss" image="contact.jpg">
    <b-container>
        <div class="py-3 text-center font-italic">
            Är du intresserad av att veta mer om oss eller de tjänster vi erbjuder?
            <br>
            Fyll i formuläret nedan så kontaktar vi dig så snart som möjligt!
        </div>
        <b-form>
            <b-row class="mb-3">
                
                <b-col>
                    <b-input type="text" placeholder="Ditt namn" v-model="name" />
                </b-col>
            </b-row>
            <b-row class="mb-3">
                
                <b-col>
                    <b-input type="email" placeholder="Din e-postadress" v-model="email" />
                </b-col>
            </b-row>
            <b-row class="mb-3">
                
                <b-col>
                    <b-form-textarea v-model="message" placeholder="Ditt meddelande..." rows="4" max-rows="6"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary">Skicka</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</Page>
</template>

<script>
const Page = () => import("@/components/page/Page");

export default {
    name: 'Home',
    components: {
        Page
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {
        MediaImage(id) {
            return this.$images.getMediaImage(id);
        }
    }
};
</script>
